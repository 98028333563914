<template>
    <!-- 人员管理 -->
    <div class="setBox">
        <div class="selectionBar">
            <div class="flex flex-ai-c flex-wrap">
                <label>场景：</label>
                <el-button type="primary" size="small" @click="edit()" style="margin-left:auto">
                    <i class="el-icon-circle-plus-outline"></i>
                    添加人员
                </el-button>
            </div>
        </div>

        <!-- 表格 -->
        <div class="table" style="height:calc( 100% - 135px )">
            <el-table ref="multipleTable" :data="list" :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" highlight-current-row stripe height="calc( 100% - 40px )">
                <el-table-column prop="name" label="姓名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="username" label="用户名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="mobile" label="手机号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="systemRole" label="角色" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.systemRole">{{scope.row.systemRole.name}}</span>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="200" align="center">
                    <template slot-scope="scope">
                        <el-button-group>
                            <el-button type="primary" icon="el-icon-edit" size="small" @click="edit(scope.row)"></el-button>
                            <el-button type="primary" icon="el-icon-delete" size="small" @click="del(scope.row)"></el-button>
                        </el-button-group>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>

        <!-- 新建框 -->
        <el-dialog :title="title" :visible.sync="dialogFormVisible" :modal-append-to-body="false" :close-on-click-modal="false" width="600px" @close="closeDialog">
            <el-form ref="formAuth" :rules="formRules" :model="formData" label-position="top" size="small" class="alertForm">
                <el-form-item class="hide"></el-form-item>
                <el-form-item label="姓名" prop="name" style="width:100%">
                    <el-input v-model="formData.name" autocomplete="off" placeholder="请输入姓名"></el-input>
                </el-form-item>
                <el-form-item class="hide"></el-form-item>
                <el-form-item label="用户名" prop="username" style="width:100%">
                    <el-input v-model="formData.username" autocomplete="off" placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item class="hide"></el-form-item>
                <el-form-item label="手机号" prop="mobile" style="width:100%">
                    <el-input v-model="formData.mobile" placeholder="请输入手机号" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item class="hide"></el-form-item>
                <el-form-item label="密码(如无需修改留空即可)" :prop="ispwd?'password':''" style="width:100%">
                    <el-input v-model="formData.password" show-password autocomplete="off" placeholder="请输入密码"></el-input>
                </el-form-item>
                <el-form-item class="hide"></el-form-item>
                <el-form-item label="角色" prop="role_id" style="width:100%">
                    <el-select v-model="formData.role_id" size="small" placeholder="请选择角色" readonly style="width:100%" @change="roleChange">
                        <el-option v-for="item in roleList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="hide"></el-form-item>
                <el-form-item label="区域" v-if="formData.role_id == 3" :prop="formData.role_id == 3 ? 'area_code' : '' " style="width:100%">
                    <el-select v-model="formData.area_code" size="small" placeholder="请选择区域" multiple readonly style="width:100%">
                        <el-option v-for="item in areaList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="区域" v-if="formData.role_id == 2" :prop="formData.role_id == 2 ? 'area_code' : '' " style="width:100%">
                    <el-select v-model="formData.area_code" size="small" multiple placeholder="请选择区域" readonly style="width:100%">
                        <el-option v-for="item in areaList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="closeDialog()">取 消</el-button>
                <el-button size="small" type="primary" @click="submit()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: '添加人员',
            list: [],
            formData: {
                area_code:[]
            },
            roleList: [],//角色列表
            areaList: [],//区域列表
            balance_is:2,
            props: {
                label: 'name',
                value: 'id',
            },
            formRules: {
                name: [{
                    required: true,
                    message: '请输入姓名',
                    trigger: 'blur'
                }],
                username: [{
                    required: true,
                    message: '请输入用户名',
                    trigger: 'blur'
                }],
                mobile: [{
                    required: true,
                    pattern: /^1[0-9][0-9]\d{8}$/,
                    message: '请输入手机号',
                    trigger: 'change'
                }],
                role_id: [{
                    required: true,
                    message: '请选择角色',
                    trigger: 'blur'
                }],
                password: [{
                    required: true,
                    message: '请输入密码',
                    trigger: 'blur'
                }],
                area_code: [{
                    required: true,
                    message: '请选择区域',
                    trigger: 'blur'
                }]
            },

            curr: 1, //页码
            row: 20, //每页条数
            pages: 1, //总页数
            count: 0, //总条数

            dialogFormVisible: false,
            ispwd: true,
        }
    },
    mounted() {
        this.getList();
        this.getRole();
        this.getArea();
    },
    methods: {
        getArea() {
            this.http.post('/admin.area/indexHengShui').then(response => {
                this.areaList = response;
            })
        },
        getRole() {
            this.http.post('/admin.SystemRole/index').then(response => {
                this.roleList = response;
            })
        },
        //人员列表
        getList() {
            this.http.post('/admin.admin/index', {
                curr: this.curr,
                row: this.row,
            }).then(res => {
                this.list = res.list;
                this.curr = res.curr;
                this.pages = res.pages;
                this.count = res.count;
            });
        },
        roleChange() {
           this.formData.area_code = [];
        },

        //编辑
        edit(row) {
            this.title = row ? '编辑人员' : '添加人员';
            this.ispwd = row ? false : true;
            
            this.formData = row ? JSON.parse(JSON.stringify(row)) : {
                area_code:[]
            };
            this.dialogFormVisible = true;
        },
        handleChange(val) {
            this.formData.area_code = val[2]
        },
        del(row) {
            this.$confirm(`确定删除吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            }).then(() => {
                this.http.post('/admin.admin/delete', {
                    id: row.id,
                }).then(() => {
                    this.getList();
                    this.$message.success('已删除!');
                })
            })
        },

        //关闭弹框
        closeDialog() {
            this.$refs.formAuth.resetFields();
            this.dialogFormVisible = false
        },
        //保存数据
        submit() {
            this.$refs['formAuth'].validate((valid) => {
                if (valid) {
                    this.http.post("/admin.admin/edit", this.formData).then(() => {
                        this.closeDialog();
                        this.$message.success('提交成功');
                        this.getList();
                    });
                } else {
                    return false;
                }
            });
        },
        //翻页
        currentChange: function (curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function (row) {
            this.row = row;
            this.getList();
        },
    }
}
</script>

<style lang="css" scoped>

</style>
